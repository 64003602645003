// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.5
//   protoc               v6.30.1
// source: go.chromium.org/luci/analysis/proto/v1/rules.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";
import { FieldMask } from "../../../../../google/protobuf/field_mask.pb";
import { Timestamp } from "../../../../../google/protobuf/timestamp.pb";
import { AssociatedBug, ClusterId } from "./common.pb";
import { FailureReason } from "./failure_reason.pb";
import {
  BuganizerPriority,
  buganizerPriorityFromJSON,
  buganizerPriorityToJSON,
  BugComponent,
} from "./issue_tracking.pb";

export const protobufPackage = "luci.analysis.v1";

/** A rule associating failures with a bug. */
export interface Rule {
  /**
   * The resource name of the failure association rule.
   * Can be used to refer to this rule, e.g. in Rules.Get RPC.
   * Format: projects/{project}/rules/{rule_id}.
   * See also https://google.aip.dev/122.
   */
  readonly name: string;
  /** The LUCI Project for which this rule is defined. */
  readonly project: string;
  /**
   * The unique identifier for the failure association rule,
   * as 32 lowercase hexadecimal characters.
   */
  readonly ruleId: string;
  /**
   * The rule predicate, defining which failures are being associated.
   * For example, 'reason LIKE "Some error: %"'.
   *
   * analysis/internal/clustering/rules/lang/lang.go contains the
   * EBNF grammar for the language used to define rule predicates;
   * it is a subset of Google Standard SQL.
   *
   * The maximum allowed length is 65536 characters.
   */
  readonly ruleDefinition: string;
  /** The bug that the failures are associated with. */
  readonly bug:
    | AssociatedBug
    | undefined;
  /**
   * Whether the bug should be updated by LUCI Analysis, and whether
   * failures should still be matched against the rule.
   */
  readonly isActive: boolean;
  /**
   * Whether LUCI Analysis should manage the priority and verified status
   * of the associated bug based on the impact established via this rule.
   */
  readonly isManagingBug: boolean;
  /**
   * Determines whether LUCI Analysis is managing the bug priority updates
   * of the bug.
   */
  readonly isManagingBugPriority: boolean;
  /** Output Only. The time is_managing_bug_priority was last updated. */
  readonly isManagingBugPriorityLastUpdateTime:
    | string
    | undefined;
  /**
   * The suggested cluster this rule was created from (if any).
   * Until re-clustering is complete and has reduced the residual impact
   * of the source cluster, this cluster ID tells bug filing to ignore
   * the source cluster when determining whether new bugs need to be filed.
   * Immutable after creation.
   */
  readonly sourceCluster:
    | ClusterId
    | undefined;
  /**
   * Bug management state.
   * System controlled data, cannot be modified by the user.
   */
  readonly bugManagementState:
    | BugManagementState
    | undefined;
  /** The time the rule was created. */
  readonly createTime:
    | string
    | undefined;
  /**
   * The user which created the rule.
   * This could be an email address or the value 'system' (for rules
   * automaticatically created by LUCI Analysis itself).
   * This value may not be available, as its disclosure is limited
   * to Googlers only and is subject to automatic deletion after 30 days.
   */
  readonly createUser: string;
  /**
   * The last time an auditable field was updated. An auditable field
   * is any field other than a system controlled data field.
   */
  readonly lastAuditableUpdateTime:
    | string
    | undefined;
  /**
   * The last user which updated an auditable field. An auditable field
   * is any field other than a system controlled data field.
   * This could be an email address or the value 'system' (for rules
   * automaticatically modified by LUCI Analysis itself).
   * This value may not be available, as its disclosure is limited
   * to Googlers only and is subject to automatic deletion after 30 days.
   */
  readonly lastAuditableUpdateUser: string;
  /** The time the rule was last updated. */
  readonly lastUpdateTime:
    | string
    | undefined;
  /**
   * The time the rule was last updated in a way that caused the
   * matched failures to change, i.e. because of a change to rule_definition
   * or is_active. (By contrast, updating the associated bug does NOT change
   * the matched failures, so does NOT update this field.)
   * Output only.
   */
  readonly predicateLastUpdateTime:
    | string
    | undefined;
  /**
   * This checksum is computed by the server based on the value of other
   * fields, and may be sent on update requests to ensure the client
   * has an up-to-date value before proceeding.
   * See also https://google.aip.dev/154.
   */
  readonly etag: string;
}

/** BugManagementState is the state of bug management for a rule. */
export interface BugManagementState {
  /** The state of each bug management policy. */
  readonly policyState: readonly BugManagementState_PolicyState[];
}

/** The state of a bug management policy for a rule. */
export interface BugManagementState_PolicyState {
  /** The identifier of the bug management policy. */
  readonly policyId: string;
  /**
   * Whether the given policy is active for the rule.
   * Updated on every bug-filing run as follows:
   * - Set to true if the policy activation criteria was met.
   * - Set to false if the policy deactivation criteria was met.
   */
  readonly isActive: boolean;
  /**
   * The last time the policy was made active.
   * Allows detecting if policy is made active for the first time (as a
   * zero last_activation_time indicates the policy was never active).
   * Allows UI to filter to showing policies that were at least once active.
   * Allows UI to sort which policy was most recently active.
   * Allows UI to show when a policy last activated.
   */
  readonly lastActivationTime:
    | string
    | undefined;
  /**
   * The last time the policy was made inactive.
   * Allows UI to show when a policy last deactivated.
   */
  readonly lastDeactivationTime: string | undefined;
}

export interface GetRuleRequest {
  /**
   * The name of the rule to retrieve.
   * Format: projects/{project}/rules/{rule_id}.
   */
  readonly name: string;
}

export interface ListRulesRequest {
  /**
   * The parent, which owns this collection of rules.
   * Format: projects/{project}.
   */
  readonly parent: string;
}

export interface ListRulesResponse {
  /** The rules. */
  readonly rules: readonly Rule[];
}

export interface CreateRuleRequest {
  /**
   * The parent resource where the rule will be created.
   * Format: projects/{project}.
   */
  readonly parent: string;
  /**
   * The rule to create.
   *
   * The `name` of the rule should be left empty, it will be automatically
   * assigned by the service.
   */
  readonly rule: Rule | undefined;
}

export interface UpdateRuleRequest {
  /**
   * The rule to update.
   *
   * The rule's `name` field is used to identify the book to update.
   * Format: projects/{project}/rules/{rule_id}.
   */
  readonly rule:
    | Rule
    | undefined;
  /** The list of fields to update. */
  readonly updateMask:
    | readonly string[]
    | undefined;
  /**
   * The current etag of the rule.
   * If an etag is provided and does not match the current etag of the rule,
   * update will be blocked and an ABORTED error will be returned.
   */
  readonly etag: string;
}

export interface LookupBugRequest {
  /**
   * System is the bug tracking system of the bug. This is either
   * "monorail" or "buganizer".
   */
  readonly system: string;
  /**
   * Id is the bug tracking system-specific identity of the bug.
   * For monorail, the scheme is {project}/{numeric_id}, for
   * buganizer the scheme is {numeric_id}.
   */
  readonly id: string;
}

export interface LookupBugResponse {
  /**
   * The rules corresponding to the requested bug.
   * Format: projects/{project}/rules/{rule_id}.
   */
  readonly rules: readonly string[];
}

/**
 * PrepareRuleDefaultsRequest represents a request to prepare default
 * values for a new rule.
 */
export interface PrepareRuleDefaultsRequest {
  /**
   * The parent LUCI Project, which will own the rule.
   * Format: projects/{project}.
   */
  readonly parent: string;
  /**
   * The test failure to create a rule for. If set, a failure
   * association rule that matches it will be included in the
   * response.
   */
  readonly testResult: PrepareRuleDefaultsRequest_TestResult | undefined;
}

/** The unexpectedly failed/crashed/aborted test result. */
export interface PrepareRuleDefaultsRequest_TestResult {
  /** The test identifier. */
  readonly testId: string;
  /** The failure reason. */
  readonly failureReason: FailureReason | undefined;
}

export interface PrepareRuleDefaultsResponse {
  /**
   * The rule with defaults such as is_active and the failure
   * association rule populated.
   */
  readonly rule: Rule | undefined;
}

export interface CreateRuleWithNewIssueRequest {
  /**
   * The parent resource where the rule will be created.
   * Format: projects/{project}.
   */
  readonly parent: string;
  /**
   * The rule to create.
   *
   * The `bug` field should not be set, it will be automatically populated
   * based on the issue that is filed as part of this request.
   *
   * The `name` of the rule should be left empty, it will be automatically
   * assigned by the service.
   */
  readonly rule:
    | Rule
    | undefined;
  /** The issue to create alongside this rule. */
  readonly issue: CreateRuleWithNewIssueRequest_Issue | undefined;
}

/** Contents of the issue to create alongside this rule. */
export interface CreateRuleWithNewIssueRequest_Issue {
  /**
   * The issue tracker component to file the bug into. Currently, only
   * IssueTracker (Buganizer) components are supported.
   */
  readonly component:
    | BugComponent
    | undefined;
  /**
   * Short title to use for the new issue.
   * Maximum length is 250 bytes. Content must be UTF-8
   * normal form C, with printable characters only.
   */
  readonly title: string;
  /**
   * The issue description (initial comment).
   * Maximum length is 100,000 characters. Content must be UTF-8
   * normal form C, with printable characters only.
   *
   * LUCI Analysis will append its own footer to this
   * description when creating the issue.
   * This footer will contain a link to the rule in LUCI Analysis,
   * and identity of the user who invoked this RPC.
   *
   * For example:
   * "<Supplied issue comment.>
   *
   * See failure examples and modify the failure associated with this bug in LUCI Analysis: <link to rule>.
   * Filed on behalf of someone@google.com."
   */
  readonly comment: string;
  /** The issue priority. */
  readonly priority: BuganizerPriority;
  /** The issue access limit to use for the new issue. */
  readonly accessLimit: CreateRuleWithNewIssueRequest_Issue_IssueAccessLimit;
}

/** The issue access restriction level. */
export enum CreateRuleWithNewIssueRequest_Issue_IssueAccessLimit {
  /** ISSUE_ACCESS_LIMIT_UNSPECIFIED - Default value. Do not use. */
  ISSUE_ACCESS_LIMIT_UNSPECIFIED = 0,
  /**
   * None - No issue access limits apply. All users who can
   * access the component can see the issue.
   */
  None = 1,
  /**
   * Trusted - Only actors on the issue or trusted users (Full-time Googlers +
   * Common tools by default) users retain view access.
   */
  Trusted = 4,
}

export function createRuleWithNewIssueRequest_Issue_IssueAccessLimitFromJSON(
  object: any,
): CreateRuleWithNewIssueRequest_Issue_IssueAccessLimit {
  switch (object) {
    case 0:
    case "ISSUE_ACCESS_LIMIT_UNSPECIFIED":
      return CreateRuleWithNewIssueRequest_Issue_IssueAccessLimit.ISSUE_ACCESS_LIMIT_UNSPECIFIED;
    case 1:
    case "None":
      return CreateRuleWithNewIssueRequest_Issue_IssueAccessLimit.None;
    case 4:
    case "Trusted":
      return CreateRuleWithNewIssueRequest_Issue_IssueAccessLimit.Trusted;
    default:
      throw new globalThis.Error(
        "Unrecognized enum value " + object + " for enum CreateRuleWithNewIssueRequest_Issue_IssueAccessLimit",
      );
  }
}

export function createRuleWithNewIssueRequest_Issue_IssueAccessLimitToJSON(
  object: CreateRuleWithNewIssueRequest_Issue_IssueAccessLimit,
): string {
  switch (object) {
    case CreateRuleWithNewIssueRequest_Issue_IssueAccessLimit.ISSUE_ACCESS_LIMIT_UNSPECIFIED:
      return "ISSUE_ACCESS_LIMIT_UNSPECIFIED";
    case CreateRuleWithNewIssueRequest_Issue_IssueAccessLimit.None:
      return "None";
    case CreateRuleWithNewIssueRequest_Issue_IssueAccessLimit.Trusted:
      return "Trusted";
    default:
      throw new globalThis.Error(
        "Unrecognized enum value " + object + " for enum CreateRuleWithNewIssueRequest_Issue_IssueAccessLimit",
      );
  }
}

function createBaseRule(): Rule {
  return {
    name: "",
    project: "",
    ruleId: "",
    ruleDefinition: "",
    bug: undefined,
    isActive: false,
    isManagingBug: false,
    isManagingBugPriority: false,
    isManagingBugPriorityLastUpdateTime: undefined,
    sourceCluster: undefined,
    bugManagementState: undefined,
    createTime: undefined,
    createUser: "",
    lastAuditableUpdateTime: undefined,
    lastAuditableUpdateUser: "",
    lastUpdateTime: undefined,
    predicateLastUpdateTime: undefined,
    etag: "",
  };
}

export const Rule: MessageFns<Rule> = {
  encode(message: Rule, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.project !== "") {
      writer.uint32(18).string(message.project);
    }
    if (message.ruleId !== "") {
      writer.uint32(26).string(message.ruleId);
    }
    if (message.ruleDefinition !== "") {
      writer.uint32(34).string(message.ruleDefinition);
    }
    if (message.bug !== undefined) {
      AssociatedBug.encode(message.bug, writer.uint32(42).fork()).join();
    }
    if (message.isActive !== false) {
      writer.uint32(48).bool(message.isActive);
    }
    if (message.isManagingBug !== false) {
      writer.uint32(112).bool(message.isManagingBug);
    }
    if (message.isManagingBugPriority !== false) {
      writer.uint32(120).bool(message.isManagingBugPriority);
    }
    if (message.isManagingBugPriorityLastUpdateTime !== undefined) {
      Timestamp.encode(toTimestamp(message.isManagingBugPriorityLastUpdateTime), writer.uint32(130).fork()).join();
    }
    if (message.sourceCluster !== undefined) {
      ClusterId.encode(message.sourceCluster, writer.uint32(58).fork()).join();
    }
    if (message.bugManagementState !== undefined) {
      BugManagementState.encode(message.bugManagementState, writer.uint32(138).fork()).join();
    }
    if (message.createTime !== undefined) {
      Timestamp.encode(toTimestamp(message.createTime), writer.uint32(66).fork()).join();
    }
    if (message.createUser !== "") {
      writer.uint32(74).string(message.createUser);
    }
    if (message.lastAuditableUpdateTime !== undefined) {
      Timestamp.encode(toTimestamp(message.lastAuditableUpdateTime), writer.uint32(146).fork()).join();
    }
    if (message.lastAuditableUpdateUser !== "") {
      writer.uint32(154).string(message.lastAuditableUpdateUser);
    }
    if (message.lastUpdateTime !== undefined) {
      Timestamp.encode(toTimestamp(message.lastUpdateTime), writer.uint32(82).fork()).join();
    }
    if (message.predicateLastUpdateTime !== undefined) {
      Timestamp.encode(toTimestamp(message.predicateLastUpdateTime), writer.uint32(106).fork()).join();
    }
    if (message.etag !== "") {
      writer.uint32(98).string(message.etag);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Rule {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRule() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.project = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.ruleId = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.ruleDefinition = reader.string();
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.bug = AssociatedBug.decode(reader, reader.uint32());
          continue;
        }
        case 6: {
          if (tag !== 48) {
            break;
          }

          message.isActive = reader.bool();
          continue;
        }
        case 14: {
          if (tag !== 112) {
            break;
          }

          message.isManagingBug = reader.bool();
          continue;
        }
        case 15: {
          if (tag !== 120) {
            break;
          }

          message.isManagingBugPriority = reader.bool();
          continue;
        }
        case 16: {
          if (tag !== 130) {
            break;
          }

          message.isManagingBugPriorityLastUpdateTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        }
        case 7: {
          if (tag !== 58) {
            break;
          }

          message.sourceCluster = ClusterId.decode(reader, reader.uint32());
          continue;
        }
        case 17: {
          if (tag !== 138) {
            break;
          }

          message.bugManagementState = BugManagementState.decode(reader, reader.uint32());
          continue;
        }
        case 8: {
          if (tag !== 66) {
            break;
          }

          message.createTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        }
        case 9: {
          if (tag !== 74) {
            break;
          }

          message.createUser = reader.string();
          continue;
        }
        case 18: {
          if (tag !== 146) {
            break;
          }

          message.lastAuditableUpdateTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        }
        case 19: {
          if (tag !== 154) {
            break;
          }

          message.lastAuditableUpdateUser = reader.string();
          continue;
        }
        case 10: {
          if (tag !== 82) {
            break;
          }

          message.lastUpdateTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        }
        case 13: {
          if (tag !== 106) {
            break;
          }

          message.predicateLastUpdateTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        }
        case 12: {
          if (tag !== 98) {
            break;
          }

          message.etag = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Rule {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      project: isSet(object.project) ? globalThis.String(object.project) : "",
      ruleId: isSet(object.ruleId) ? globalThis.String(object.ruleId) : "",
      ruleDefinition: isSet(object.ruleDefinition) ? globalThis.String(object.ruleDefinition) : "",
      bug: isSet(object.bug) ? AssociatedBug.fromJSON(object.bug) : undefined,
      isActive: isSet(object.isActive) ? globalThis.Boolean(object.isActive) : false,
      isManagingBug: isSet(object.isManagingBug) ? globalThis.Boolean(object.isManagingBug) : false,
      isManagingBugPriority: isSet(object.isManagingBugPriority)
        ? globalThis.Boolean(object.isManagingBugPriority)
        : false,
      isManagingBugPriorityLastUpdateTime: isSet(object.isManagingBugPriorityLastUpdateTime)
        ? globalThis.String(object.isManagingBugPriorityLastUpdateTime)
        : undefined,
      sourceCluster: isSet(object.sourceCluster) ? ClusterId.fromJSON(object.sourceCluster) : undefined,
      bugManagementState: isSet(object.bugManagementState)
        ? BugManagementState.fromJSON(object.bugManagementState)
        : undefined,
      createTime: isSet(object.createTime) ? globalThis.String(object.createTime) : undefined,
      createUser: isSet(object.createUser) ? globalThis.String(object.createUser) : "",
      lastAuditableUpdateTime: isSet(object.lastAuditableUpdateTime)
        ? globalThis.String(object.lastAuditableUpdateTime)
        : undefined,
      lastAuditableUpdateUser: isSet(object.lastAuditableUpdateUser)
        ? globalThis.String(object.lastAuditableUpdateUser)
        : "",
      lastUpdateTime: isSet(object.lastUpdateTime) ? globalThis.String(object.lastUpdateTime) : undefined,
      predicateLastUpdateTime: isSet(object.predicateLastUpdateTime)
        ? globalThis.String(object.predicateLastUpdateTime)
        : undefined,
      etag: isSet(object.etag) ? globalThis.String(object.etag) : "",
    };
  },

  toJSON(message: Rule): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.project !== "") {
      obj.project = message.project;
    }
    if (message.ruleId !== "") {
      obj.ruleId = message.ruleId;
    }
    if (message.ruleDefinition !== "") {
      obj.ruleDefinition = message.ruleDefinition;
    }
    if (message.bug !== undefined) {
      obj.bug = AssociatedBug.toJSON(message.bug);
    }
    if (message.isActive !== false) {
      obj.isActive = message.isActive;
    }
    if (message.isManagingBug !== false) {
      obj.isManagingBug = message.isManagingBug;
    }
    if (message.isManagingBugPriority !== false) {
      obj.isManagingBugPriority = message.isManagingBugPriority;
    }
    if (message.isManagingBugPriorityLastUpdateTime !== undefined) {
      obj.isManagingBugPriorityLastUpdateTime = message.isManagingBugPriorityLastUpdateTime;
    }
    if (message.sourceCluster !== undefined) {
      obj.sourceCluster = ClusterId.toJSON(message.sourceCluster);
    }
    if (message.bugManagementState !== undefined) {
      obj.bugManagementState = BugManagementState.toJSON(message.bugManagementState);
    }
    if (message.createTime !== undefined) {
      obj.createTime = message.createTime;
    }
    if (message.createUser !== "") {
      obj.createUser = message.createUser;
    }
    if (message.lastAuditableUpdateTime !== undefined) {
      obj.lastAuditableUpdateTime = message.lastAuditableUpdateTime;
    }
    if (message.lastAuditableUpdateUser !== "") {
      obj.lastAuditableUpdateUser = message.lastAuditableUpdateUser;
    }
    if (message.lastUpdateTime !== undefined) {
      obj.lastUpdateTime = message.lastUpdateTime;
    }
    if (message.predicateLastUpdateTime !== undefined) {
      obj.predicateLastUpdateTime = message.predicateLastUpdateTime;
    }
    if (message.etag !== "") {
      obj.etag = message.etag;
    }
    return obj;
  },

  create(base?: DeepPartial<Rule>): Rule {
    return Rule.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Rule>): Rule {
    const message = createBaseRule() as any;
    message.name = object.name ?? "";
    message.project = object.project ?? "";
    message.ruleId = object.ruleId ?? "";
    message.ruleDefinition = object.ruleDefinition ?? "";
    message.bug = (object.bug !== undefined && object.bug !== null) ? AssociatedBug.fromPartial(object.bug) : undefined;
    message.isActive = object.isActive ?? false;
    message.isManagingBug = object.isManagingBug ?? false;
    message.isManagingBugPriority = object.isManagingBugPriority ?? false;
    message.isManagingBugPriorityLastUpdateTime = object.isManagingBugPriorityLastUpdateTime ?? undefined;
    message.sourceCluster = (object.sourceCluster !== undefined && object.sourceCluster !== null)
      ? ClusterId.fromPartial(object.sourceCluster)
      : undefined;
    message.bugManagementState = (object.bugManagementState !== undefined && object.bugManagementState !== null)
      ? BugManagementState.fromPartial(object.bugManagementState)
      : undefined;
    message.createTime = object.createTime ?? undefined;
    message.createUser = object.createUser ?? "";
    message.lastAuditableUpdateTime = object.lastAuditableUpdateTime ?? undefined;
    message.lastAuditableUpdateUser = object.lastAuditableUpdateUser ?? "";
    message.lastUpdateTime = object.lastUpdateTime ?? undefined;
    message.predicateLastUpdateTime = object.predicateLastUpdateTime ?? undefined;
    message.etag = object.etag ?? "";
    return message;
  },
};

function createBaseBugManagementState(): BugManagementState {
  return { policyState: [] };
}

export const BugManagementState: MessageFns<BugManagementState> = {
  encode(message: BugManagementState, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.policyState) {
      BugManagementState_PolicyState.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): BugManagementState {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBugManagementState() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.policyState.push(BugManagementState_PolicyState.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BugManagementState {
    return {
      policyState: globalThis.Array.isArray(object?.policyState)
        ? object.policyState.map((e: any) => BugManagementState_PolicyState.fromJSON(e))
        : [],
    };
  },

  toJSON(message: BugManagementState): unknown {
    const obj: any = {};
    if (message.policyState?.length) {
      obj.policyState = message.policyState.map((e) => BugManagementState_PolicyState.toJSON(e));
    }
    return obj;
  },

  create(base?: DeepPartial<BugManagementState>): BugManagementState {
    return BugManagementState.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<BugManagementState>): BugManagementState {
    const message = createBaseBugManagementState() as any;
    message.policyState = object.policyState?.map((e) => BugManagementState_PolicyState.fromPartial(e)) || [];
    return message;
  },
};

function createBaseBugManagementState_PolicyState(): BugManagementState_PolicyState {
  return { policyId: "", isActive: false, lastActivationTime: undefined, lastDeactivationTime: undefined };
}

export const BugManagementState_PolicyState: MessageFns<BugManagementState_PolicyState> = {
  encode(message: BugManagementState_PolicyState, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.policyId !== "") {
      writer.uint32(10).string(message.policyId);
    }
    if (message.isActive !== false) {
      writer.uint32(16).bool(message.isActive);
    }
    if (message.lastActivationTime !== undefined) {
      Timestamp.encode(toTimestamp(message.lastActivationTime), writer.uint32(26).fork()).join();
    }
    if (message.lastDeactivationTime !== undefined) {
      Timestamp.encode(toTimestamp(message.lastDeactivationTime), writer.uint32(34).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): BugManagementState_PolicyState {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBugManagementState_PolicyState() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.policyId = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 16) {
            break;
          }

          message.isActive = reader.bool();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.lastActivationTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.lastDeactivationTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BugManagementState_PolicyState {
    return {
      policyId: isSet(object.policyId) ? globalThis.String(object.policyId) : "",
      isActive: isSet(object.isActive) ? globalThis.Boolean(object.isActive) : false,
      lastActivationTime: isSet(object.lastActivationTime) ? globalThis.String(object.lastActivationTime) : undefined,
      lastDeactivationTime: isSet(object.lastDeactivationTime)
        ? globalThis.String(object.lastDeactivationTime)
        : undefined,
    };
  },

  toJSON(message: BugManagementState_PolicyState): unknown {
    const obj: any = {};
    if (message.policyId !== "") {
      obj.policyId = message.policyId;
    }
    if (message.isActive !== false) {
      obj.isActive = message.isActive;
    }
    if (message.lastActivationTime !== undefined) {
      obj.lastActivationTime = message.lastActivationTime;
    }
    if (message.lastDeactivationTime !== undefined) {
      obj.lastDeactivationTime = message.lastDeactivationTime;
    }
    return obj;
  },

  create(base?: DeepPartial<BugManagementState_PolicyState>): BugManagementState_PolicyState {
    return BugManagementState_PolicyState.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<BugManagementState_PolicyState>): BugManagementState_PolicyState {
    const message = createBaseBugManagementState_PolicyState() as any;
    message.policyId = object.policyId ?? "";
    message.isActive = object.isActive ?? false;
    message.lastActivationTime = object.lastActivationTime ?? undefined;
    message.lastDeactivationTime = object.lastDeactivationTime ?? undefined;
    return message;
  },
};

function createBaseGetRuleRequest(): GetRuleRequest {
  return { name: "" };
}

export const GetRuleRequest: MessageFns<GetRuleRequest> = {
  encode(message: GetRuleRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetRuleRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetRuleRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetRuleRequest {
    return { name: isSet(object.name) ? globalThis.String(object.name) : "" };
  },

  toJSON(message: GetRuleRequest): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create(base?: DeepPartial<GetRuleRequest>): GetRuleRequest {
    return GetRuleRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<GetRuleRequest>): GetRuleRequest {
    const message = createBaseGetRuleRequest() as any;
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseListRulesRequest(): ListRulesRequest {
  return { parent: "" };
}

export const ListRulesRequest: MessageFns<ListRulesRequest> = {
  encode(message: ListRulesRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.parent !== "") {
      writer.uint32(10).string(message.parent);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListRulesRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListRulesRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.parent = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListRulesRequest {
    return { parent: isSet(object.parent) ? globalThis.String(object.parent) : "" };
  },

  toJSON(message: ListRulesRequest): unknown {
    const obj: any = {};
    if (message.parent !== "") {
      obj.parent = message.parent;
    }
    return obj;
  },

  create(base?: DeepPartial<ListRulesRequest>): ListRulesRequest {
    return ListRulesRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListRulesRequest>): ListRulesRequest {
    const message = createBaseListRulesRequest() as any;
    message.parent = object.parent ?? "";
    return message;
  },
};

function createBaseListRulesResponse(): ListRulesResponse {
  return { rules: [] };
}

export const ListRulesResponse: MessageFns<ListRulesResponse> = {
  encode(message: ListRulesResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.rules) {
      Rule.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListRulesResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListRulesResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.rules.push(Rule.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListRulesResponse {
    return { rules: globalThis.Array.isArray(object?.rules) ? object.rules.map((e: any) => Rule.fromJSON(e)) : [] };
  },

  toJSON(message: ListRulesResponse): unknown {
    const obj: any = {};
    if (message.rules?.length) {
      obj.rules = message.rules.map((e) => Rule.toJSON(e));
    }
    return obj;
  },

  create(base?: DeepPartial<ListRulesResponse>): ListRulesResponse {
    return ListRulesResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListRulesResponse>): ListRulesResponse {
    const message = createBaseListRulesResponse() as any;
    message.rules = object.rules?.map((e) => Rule.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCreateRuleRequest(): CreateRuleRequest {
  return { parent: "", rule: undefined };
}

export const CreateRuleRequest: MessageFns<CreateRuleRequest> = {
  encode(message: CreateRuleRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.parent !== "") {
      writer.uint32(10).string(message.parent);
    }
    if (message.rule !== undefined) {
      Rule.encode(message.rule, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CreateRuleRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateRuleRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.parent = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.rule = Rule.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateRuleRequest {
    return {
      parent: isSet(object.parent) ? globalThis.String(object.parent) : "",
      rule: isSet(object.rule) ? Rule.fromJSON(object.rule) : undefined,
    };
  },

  toJSON(message: CreateRuleRequest): unknown {
    const obj: any = {};
    if (message.parent !== "") {
      obj.parent = message.parent;
    }
    if (message.rule !== undefined) {
      obj.rule = Rule.toJSON(message.rule);
    }
    return obj;
  },

  create(base?: DeepPartial<CreateRuleRequest>): CreateRuleRequest {
    return CreateRuleRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<CreateRuleRequest>): CreateRuleRequest {
    const message = createBaseCreateRuleRequest() as any;
    message.parent = object.parent ?? "";
    message.rule = (object.rule !== undefined && object.rule !== null) ? Rule.fromPartial(object.rule) : undefined;
    return message;
  },
};

function createBaseUpdateRuleRequest(): UpdateRuleRequest {
  return { rule: undefined, updateMask: undefined, etag: "" };
}

export const UpdateRuleRequest: MessageFns<UpdateRuleRequest> = {
  encode(message: UpdateRuleRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.rule !== undefined) {
      Rule.encode(message.rule, writer.uint32(10).fork()).join();
    }
    if (message.updateMask !== undefined) {
      FieldMask.encode(FieldMask.wrap(message.updateMask), writer.uint32(18).fork()).join();
    }
    if (message.etag !== "") {
      writer.uint32(26).string(message.etag);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): UpdateRuleRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateRuleRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.rule = Rule.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.updateMask = FieldMask.unwrap(FieldMask.decode(reader, reader.uint32()));
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.etag = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateRuleRequest {
    return {
      rule: isSet(object.rule) ? Rule.fromJSON(object.rule) : undefined,
      updateMask: isSet(object.updateMask) ? FieldMask.unwrap(FieldMask.fromJSON(object.updateMask)) : undefined,
      etag: isSet(object.etag) ? globalThis.String(object.etag) : "",
    };
  },

  toJSON(message: UpdateRuleRequest): unknown {
    const obj: any = {};
    if (message.rule !== undefined) {
      obj.rule = Rule.toJSON(message.rule);
    }
    if (message.updateMask !== undefined) {
      obj.updateMask = FieldMask.toJSON(FieldMask.wrap(message.updateMask));
    }
    if (message.etag !== "") {
      obj.etag = message.etag;
    }
    return obj;
  },

  create(base?: DeepPartial<UpdateRuleRequest>): UpdateRuleRequest {
    return UpdateRuleRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateRuleRequest>): UpdateRuleRequest {
    const message = createBaseUpdateRuleRequest() as any;
    message.rule = (object.rule !== undefined && object.rule !== null) ? Rule.fromPartial(object.rule) : undefined;
    message.updateMask = object.updateMask ?? undefined;
    message.etag = object.etag ?? "";
    return message;
  },
};

function createBaseLookupBugRequest(): LookupBugRequest {
  return { system: "", id: "" };
}

export const LookupBugRequest: MessageFns<LookupBugRequest> = {
  encode(message: LookupBugRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.system !== "") {
      writer.uint32(10).string(message.system);
    }
    if (message.id !== "") {
      writer.uint32(18).string(message.id);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): LookupBugRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLookupBugRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.system = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.id = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LookupBugRequest {
    return {
      system: isSet(object.system) ? globalThis.String(object.system) : "",
      id: isSet(object.id) ? globalThis.String(object.id) : "",
    };
  },

  toJSON(message: LookupBugRequest): unknown {
    const obj: any = {};
    if (message.system !== "") {
      obj.system = message.system;
    }
    if (message.id !== "") {
      obj.id = message.id;
    }
    return obj;
  },

  create(base?: DeepPartial<LookupBugRequest>): LookupBugRequest {
    return LookupBugRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<LookupBugRequest>): LookupBugRequest {
    const message = createBaseLookupBugRequest() as any;
    message.system = object.system ?? "";
    message.id = object.id ?? "";
    return message;
  },
};

function createBaseLookupBugResponse(): LookupBugResponse {
  return { rules: [] };
}

export const LookupBugResponse: MessageFns<LookupBugResponse> = {
  encode(message: LookupBugResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.rules) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): LookupBugResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLookupBugResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.rules.push(reader.string());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LookupBugResponse {
    return { rules: globalThis.Array.isArray(object?.rules) ? object.rules.map((e: any) => globalThis.String(e)) : [] };
  },

  toJSON(message: LookupBugResponse): unknown {
    const obj: any = {};
    if (message.rules?.length) {
      obj.rules = message.rules;
    }
    return obj;
  },

  create(base?: DeepPartial<LookupBugResponse>): LookupBugResponse {
    return LookupBugResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<LookupBugResponse>): LookupBugResponse {
    const message = createBaseLookupBugResponse() as any;
    message.rules = object.rules?.map((e) => e) || [];
    return message;
  },
};

function createBasePrepareRuleDefaultsRequest(): PrepareRuleDefaultsRequest {
  return { parent: "", testResult: undefined };
}

export const PrepareRuleDefaultsRequest: MessageFns<PrepareRuleDefaultsRequest> = {
  encode(message: PrepareRuleDefaultsRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.parent !== "") {
      writer.uint32(10).string(message.parent);
    }
    if (message.testResult !== undefined) {
      PrepareRuleDefaultsRequest_TestResult.encode(message.testResult, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): PrepareRuleDefaultsRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePrepareRuleDefaultsRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.parent = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.testResult = PrepareRuleDefaultsRequest_TestResult.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PrepareRuleDefaultsRequest {
    return {
      parent: isSet(object.parent) ? globalThis.String(object.parent) : "",
      testResult: isSet(object.testResult)
        ? PrepareRuleDefaultsRequest_TestResult.fromJSON(object.testResult)
        : undefined,
    };
  },

  toJSON(message: PrepareRuleDefaultsRequest): unknown {
    const obj: any = {};
    if (message.parent !== "") {
      obj.parent = message.parent;
    }
    if (message.testResult !== undefined) {
      obj.testResult = PrepareRuleDefaultsRequest_TestResult.toJSON(message.testResult);
    }
    return obj;
  },

  create(base?: DeepPartial<PrepareRuleDefaultsRequest>): PrepareRuleDefaultsRequest {
    return PrepareRuleDefaultsRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<PrepareRuleDefaultsRequest>): PrepareRuleDefaultsRequest {
    const message = createBasePrepareRuleDefaultsRequest() as any;
    message.parent = object.parent ?? "";
    message.testResult = (object.testResult !== undefined && object.testResult !== null)
      ? PrepareRuleDefaultsRequest_TestResult.fromPartial(object.testResult)
      : undefined;
    return message;
  },
};

function createBasePrepareRuleDefaultsRequest_TestResult(): PrepareRuleDefaultsRequest_TestResult {
  return { testId: "", failureReason: undefined };
}

export const PrepareRuleDefaultsRequest_TestResult: MessageFns<PrepareRuleDefaultsRequest_TestResult> = {
  encode(message: PrepareRuleDefaultsRequest_TestResult, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.testId !== "") {
      writer.uint32(10).string(message.testId);
    }
    if (message.failureReason !== undefined) {
      FailureReason.encode(message.failureReason, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): PrepareRuleDefaultsRequest_TestResult {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePrepareRuleDefaultsRequest_TestResult() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.testId = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.failureReason = FailureReason.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PrepareRuleDefaultsRequest_TestResult {
    return {
      testId: isSet(object.testId) ? globalThis.String(object.testId) : "",
      failureReason: isSet(object.failureReason) ? FailureReason.fromJSON(object.failureReason) : undefined,
    };
  },

  toJSON(message: PrepareRuleDefaultsRequest_TestResult): unknown {
    const obj: any = {};
    if (message.testId !== "") {
      obj.testId = message.testId;
    }
    if (message.failureReason !== undefined) {
      obj.failureReason = FailureReason.toJSON(message.failureReason);
    }
    return obj;
  },

  create(base?: DeepPartial<PrepareRuleDefaultsRequest_TestResult>): PrepareRuleDefaultsRequest_TestResult {
    return PrepareRuleDefaultsRequest_TestResult.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<PrepareRuleDefaultsRequest_TestResult>): PrepareRuleDefaultsRequest_TestResult {
    const message = createBasePrepareRuleDefaultsRequest_TestResult() as any;
    message.testId = object.testId ?? "";
    message.failureReason = (object.failureReason !== undefined && object.failureReason !== null)
      ? FailureReason.fromPartial(object.failureReason)
      : undefined;
    return message;
  },
};

function createBasePrepareRuleDefaultsResponse(): PrepareRuleDefaultsResponse {
  return { rule: undefined };
}

export const PrepareRuleDefaultsResponse: MessageFns<PrepareRuleDefaultsResponse> = {
  encode(message: PrepareRuleDefaultsResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.rule !== undefined) {
      Rule.encode(message.rule, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): PrepareRuleDefaultsResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePrepareRuleDefaultsResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.rule = Rule.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PrepareRuleDefaultsResponse {
    return { rule: isSet(object.rule) ? Rule.fromJSON(object.rule) : undefined };
  },

  toJSON(message: PrepareRuleDefaultsResponse): unknown {
    const obj: any = {};
    if (message.rule !== undefined) {
      obj.rule = Rule.toJSON(message.rule);
    }
    return obj;
  },

  create(base?: DeepPartial<PrepareRuleDefaultsResponse>): PrepareRuleDefaultsResponse {
    return PrepareRuleDefaultsResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<PrepareRuleDefaultsResponse>): PrepareRuleDefaultsResponse {
    const message = createBasePrepareRuleDefaultsResponse() as any;
    message.rule = (object.rule !== undefined && object.rule !== null) ? Rule.fromPartial(object.rule) : undefined;
    return message;
  },
};

function createBaseCreateRuleWithNewIssueRequest(): CreateRuleWithNewIssueRequest {
  return { parent: "", rule: undefined, issue: undefined };
}

export const CreateRuleWithNewIssueRequest: MessageFns<CreateRuleWithNewIssueRequest> = {
  encode(message: CreateRuleWithNewIssueRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.parent !== "") {
      writer.uint32(10).string(message.parent);
    }
    if (message.rule !== undefined) {
      Rule.encode(message.rule, writer.uint32(18).fork()).join();
    }
    if (message.issue !== undefined) {
      CreateRuleWithNewIssueRequest_Issue.encode(message.issue, writer.uint32(26).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CreateRuleWithNewIssueRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateRuleWithNewIssueRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.parent = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.rule = Rule.decode(reader, reader.uint32());
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.issue = CreateRuleWithNewIssueRequest_Issue.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateRuleWithNewIssueRequest {
    return {
      parent: isSet(object.parent) ? globalThis.String(object.parent) : "",
      rule: isSet(object.rule) ? Rule.fromJSON(object.rule) : undefined,
      issue: isSet(object.issue) ? CreateRuleWithNewIssueRequest_Issue.fromJSON(object.issue) : undefined,
    };
  },

  toJSON(message: CreateRuleWithNewIssueRequest): unknown {
    const obj: any = {};
    if (message.parent !== "") {
      obj.parent = message.parent;
    }
    if (message.rule !== undefined) {
      obj.rule = Rule.toJSON(message.rule);
    }
    if (message.issue !== undefined) {
      obj.issue = CreateRuleWithNewIssueRequest_Issue.toJSON(message.issue);
    }
    return obj;
  },

  create(base?: DeepPartial<CreateRuleWithNewIssueRequest>): CreateRuleWithNewIssueRequest {
    return CreateRuleWithNewIssueRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<CreateRuleWithNewIssueRequest>): CreateRuleWithNewIssueRequest {
    const message = createBaseCreateRuleWithNewIssueRequest() as any;
    message.parent = object.parent ?? "";
    message.rule = (object.rule !== undefined && object.rule !== null) ? Rule.fromPartial(object.rule) : undefined;
    message.issue = (object.issue !== undefined && object.issue !== null)
      ? CreateRuleWithNewIssueRequest_Issue.fromPartial(object.issue)
      : undefined;
    return message;
  },
};

function createBaseCreateRuleWithNewIssueRequest_Issue(): CreateRuleWithNewIssueRequest_Issue {
  return { component: undefined, title: "", comment: "", priority: 0, accessLimit: 0 };
}

export const CreateRuleWithNewIssueRequest_Issue: MessageFns<CreateRuleWithNewIssueRequest_Issue> = {
  encode(message: CreateRuleWithNewIssueRequest_Issue, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.component !== undefined) {
      BugComponent.encode(message.component, writer.uint32(10).fork()).join();
    }
    if (message.title !== "") {
      writer.uint32(18).string(message.title);
    }
    if (message.comment !== "") {
      writer.uint32(26).string(message.comment);
    }
    if (message.priority !== 0) {
      writer.uint32(32).int32(message.priority);
    }
    if (message.accessLimit !== 0) {
      writer.uint32(40).int32(message.accessLimit);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CreateRuleWithNewIssueRequest_Issue {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateRuleWithNewIssueRequest_Issue() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.component = BugComponent.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.title = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.comment = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 32) {
            break;
          }

          message.priority = reader.int32() as any;
          continue;
        }
        case 5: {
          if (tag !== 40) {
            break;
          }

          message.accessLimit = reader.int32() as any;
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateRuleWithNewIssueRequest_Issue {
    return {
      component: isSet(object.component) ? BugComponent.fromJSON(object.component) : undefined,
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      comment: isSet(object.comment) ? globalThis.String(object.comment) : "",
      priority: isSet(object.priority) ? buganizerPriorityFromJSON(object.priority) : 0,
      accessLimit: isSet(object.accessLimit)
        ? createRuleWithNewIssueRequest_Issue_IssueAccessLimitFromJSON(object.accessLimit)
        : 0,
    };
  },

  toJSON(message: CreateRuleWithNewIssueRequest_Issue): unknown {
    const obj: any = {};
    if (message.component !== undefined) {
      obj.component = BugComponent.toJSON(message.component);
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.comment !== "") {
      obj.comment = message.comment;
    }
    if (message.priority !== 0) {
      obj.priority = buganizerPriorityToJSON(message.priority);
    }
    if (message.accessLimit !== 0) {
      obj.accessLimit = createRuleWithNewIssueRequest_Issue_IssueAccessLimitToJSON(message.accessLimit);
    }
    return obj;
  },

  create(base?: DeepPartial<CreateRuleWithNewIssueRequest_Issue>): CreateRuleWithNewIssueRequest_Issue {
    return CreateRuleWithNewIssueRequest_Issue.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<CreateRuleWithNewIssueRequest_Issue>): CreateRuleWithNewIssueRequest_Issue {
    const message = createBaseCreateRuleWithNewIssueRequest_Issue() as any;
    message.component = (object.component !== undefined && object.component !== null)
      ? BugComponent.fromPartial(object.component)
      : undefined;
    message.title = object.title ?? "";
    message.comment = object.comment ?? "";
    message.priority = object.priority ?? 0;
    message.accessLimit = object.accessLimit ?? 0;
    return message;
  },
};

/**
 * Provides methods to manipulate rules in LUCI Analysis, used to associate
 * failures with bugs.
 *
 * Use of LUCI is subject to the Google [Terms of Service](https://policies.google.com/terms)
 * and [Privacy Policy](https://policies.google.com/privacy).
 */
export interface Rules {
  /**
   * Retrieves a rule.
   * Designed to conform to https://google.aip.dev/131.
   */
  Get(request: GetRuleRequest): Promise<Rule>;
  /**
   * Lists rules.
   * TODO: implement pagination to make this
   * RPC compliant with https://google.aip.dev/132.
   * This RPC is incomplete. Future breaking changes are
   * expressly flagged.
   */
  List(request: ListRulesRequest): Promise<ListRulesResponse>;
  /**
   * Creates a new rule.
   * Designed to conform to https://google.aip.dev/133.
   */
  Create(request: CreateRuleRequest): Promise<Rule>;
  /**
   * Updates a rule.
   * Designed to conform to https://google.aip.dev/134.
   */
  Update(request: UpdateRuleRequest): Promise<Rule>;
  /**
   * Looks up the rule associated with a given bug, without knowledge
   * of the LUCI project the rule is in.
   * Designed to conform to https://google.aip.dev/136.
   */
  LookupBug(request: LookupBugRequest): Promise<LookupBugResponse>;
  /** Prepares default values for a rule. */
  PrepareDefaults(request: PrepareRuleDefaultsRequest): Promise<PrepareRuleDefaultsResponse>;
  /**
   * Creates a new rule and creates a new issue in an issue tracker.
   * Returns the rule that was created, which references the
   * newly filed issue.
   *
   * Designed as a compound RPC rather than separate NewIssue + CreateRule RPCs
   * to ensure that issues with the definition of the failure association rule
   * can be caught before the bug is filed (reduces chance of partial success
   * in which bug is filed but rule cannot then be created).
   * Designed to conform to https://google.aip.dev/136.
   */
  CreateWithNewIssue(request: CreateRuleWithNewIssueRequest): Promise<Rule>;
}

export const RulesServiceName = "luci.analysis.v1.Rules";
export class RulesClientImpl implements Rules {
  static readonly DEFAULT_SERVICE = RulesServiceName;
  private readonly rpc: Rpc;
  private readonly service: string;
  constructor(rpc: Rpc, opts?: { service?: string }) {
    this.service = opts?.service || RulesServiceName;
    this.rpc = rpc;
    this.Get = this.Get.bind(this);
    this.List = this.List.bind(this);
    this.Create = this.Create.bind(this);
    this.Update = this.Update.bind(this);
    this.LookupBug = this.LookupBug.bind(this);
    this.PrepareDefaults = this.PrepareDefaults.bind(this);
    this.CreateWithNewIssue = this.CreateWithNewIssue.bind(this);
  }
  Get(request: GetRuleRequest): Promise<Rule> {
    const data = GetRuleRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "Get", data);
    return promise.then((data) => Rule.fromJSON(data));
  }

  List(request: ListRulesRequest): Promise<ListRulesResponse> {
    const data = ListRulesRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "List", data);
    return promise.then((data) => ListRulesResponse.fromJSON(data));
  }

  Create(request: CreateRuleRequest): Promise<Rule> {
    const data = CreateRuleRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "Create", data);
    return promise.then((data) => Rule.fromJSON(data));
  }

  Update(request: UpdateRuleRequest): Promise<Rule> {
    const data = UpdateRuleRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "Update", data);
    return promise.then((data) => Rule.fromJSON(data));
  }

  LookupBug(request: LookupBugRequest): Promise<LookupBugResponse> {
    const data = LookupBugRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "LookupBug", data);
    return promise.then((data) => LookupBugResponse.fromJSON(data));
  }

  PrepareDefaults(request: PrepareRuleDefaultsRequest): Promise<PrepareRuleDefaultsResponse> {
    const data = PrepareRuleDefaultsRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "PrepareDefaults", data);
    return promise.then((data) => PrepareRuleDefaultsResponse.fromJSON(data));
  }

  CreateWithNewIssue(request: CreateRuleWithNewIssueRequest): Promise<Rule> {
    const data = CreateRuleWithNewIssueRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "CreateWithNewIssue", data);
    return promise.then((data) => Rule.fromJSON(data));
  }
}

interface Rpc {
  request(service: string, method: string, data: unknown): Promise<unknown>;
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function toTimestamp(dateStr: string): Timestamp {
  const date = new globalThis.Date(dateStr);
  const seconds = Math.trunc(date.getTime() / 1_000).toString();
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): string {
  let millis = (globalThis.Number(t.seconds) || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis).toISOString();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create(base?: DeepPartial<T>): T;
  fromPartial(object: DeepPartial<T>): T;
}
